import React, {useEffect, useState} from 'react';

import Api from "utils/Api";
import * as Auth from "components/Auth";
import * as G from "utils/utils";
import {PrinterIcon} from "@heroicons/react/24/outline";

export default function PassRortForm({sail=null, openWindow = openWindow}) {
    const user = Auth.AuthCheck();
    const [sailNo, setSailNo] = useState();
    const [userList, setUserList] = useState([]);
    const [sailInfo, setSailInfo] = useState(G.SailInfo);

    useEffect(() => {
        if(sail) {
            if(sail.sailing_no) {
                setSailNo(sail.sailing_no);
                ApiListBoarding(sail.sailing_no);
            }
        }
    }, [sail]);

    // const openWindow = () => {
    //     // const url = `/manage/boarding/pass/print/${sailNo}`;
    //     const url = `/manage/boarding/print/pass/${sailNo}`;
    //     window.open(url, "_blank", "width=620, height=700, noopener, noreferrer");
    // }

    const ApiListBoarding = (sailing_no) => {
        const url = "boarding/boarding_pass_user_list";
        let body = { sailing_no: sailing_no}
        Api(body, url, user.token).then(
            (response) => {
                const result = response.result || response;
                if (result.code !== 0 ) {
                    alert(result.message);
                    return;
                }
                const dataCount = response.data.info.count;
                const dataList = response.data.user_list;
                setUserList(dataList);
                setSailInfo(response.data.info);

            }
        );
    };

    return (
        <>
            <div className="form-row">
                <div className="w-full">
                    <table className="table table-xs md:table-md w-full bg-base-200 shadow-md">
                        <tbody>
                        <tr>
                            <td colSpan={2}>선명 : {sailInfo.cruise_name}</td>
                            <td colSpan={2}>톤수 : {sailInfo.cruise_weight} </td>
                            <td colSpan={2}>승객 : {sailInfo.boarding_user_count}</td>
                            <td colSpan={2}>선원 : {sailInfo.mate_count}</td>
                        </tr>

                        <tr>
                            <td colSpan={4}>출항일시 : {sailInfo.start_at}</td>
                            <td colSpan={4}>입항일시 : {sailInfo.end_at}</td>
                        </tr>
                        <tr>
                            <td colSpan={6}>탑승장소 : {sailInfo.location_address}</td>
                            <td colSpan={1} className="text-right">
                                <button type="button" className="btn btn-info btn-xs md:btn-sm w-full"
                                        onClick={openWindow}>
                                    <PrinterIcon className="w-5 h-5" /> 출력
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="divider-vertical py-6"></div>
            <div className="form-row">
                <table className="table table-xs md:table-md w-full bg-base-100 shadow-md text-center">
                    <thead>
                    <tr>
                        <th>번호</th>
                        <th>구분</th>
                        <th>성명</th>
                        <th>생년월일</th>
                        <th>성별</th>
                        <th>휴대폰번호</th>
                    </tr>
                    </thead>
                    <tbody>
                    {userList.map(({boarding_no,boarding_name,boarding_birth,boarding_phone,boarding_gender,boarding_at,port_entry_at,gubun},k) =>
                        <tr key={boarding_no}>
                            <td>{k+1}</td>
                            <td>
                                {(gubun === 'user')?
                                    <div className="badge badge-accent">승객</div>
                                :
                                    <div className="badge badge-ghost">승무원</div>
                                }
                            </td>
                            <td>{boarding_name}</td>
                            <td>{boarding_birth}</td>
                            <td>{G.GenderText(boarding_gender)}</td>
                            <td>{G.Hypen(boarding_phone)}</td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </>
    )
}
