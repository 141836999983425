import React, {useEffect, useRef, useState} from 'react';
import {useParams} from "react-router-dom";
import jsPdf from "jspdf";
import html2canvas from "html2canvas";
import ReactToPrint from "react-to-print";
import dayjs from "dayjs";
import {ArrowDownTrayIcon, PrinterIcon} from "@heroicons/react/24/outline";

import Api from "utils/Api";
import * as G from "utils/utils";
import 'styles/print.scss';

export default function PassPrint({sail=null, reserve=null, onFinish}) {
    const { sailNo } = useParams();
    const ref = useRef();

    const [userList, setUserList] = useState([]);
    const [sailInfo, setSailInfo] = useState(G.SailInfo);
    const _token = localStorage.getItem('accessToken');

    const printPDF = () => {
        const domElement = document.getElementById("print-area-pass");
        html2canvas(domElement
            , (
                {
                    useCORS: true,
                    scrollY: 0,
                    width: 595,
                    scale: 2.5,
                    dpi: 300,
                    letterRendering: true,
                    allowTaint: false,
                }
            )
        ).then(canvas => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPdf("p", "mm", "a4");

            const imageWidth = pdf.internal.pageSize.getWidth(); //210;
            const pageHeight = pdf.internal.pageSize.getHeight(); //295;
            let imageHeight = canvas.height * imageWidth / canvas.width;
            let heightLeft = imageHeight;
            let position = 0;
            pdf.addImage(imgData, 'PNG', 0, position, imageWidth, imageHeight);
            heightLeft -= pageHeight;
            while (heightLeft >= 1) {
                position = heightLeft - imageHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imageWidth, imageHeight);
                heightLeft -= pageHeight;
            }
            //
            // const width = pdf.internal.pageSize.getWidth();
            // const height = pdf.internal.pageSize.getHeight();
            // console.log('width',width);
            // console.log('height',height);
            // pdf.addImage(imgData, "JPEG", 0, 0);//, width, height);
            const fdate = dayjs().format("YYYY-MM-DD");
            pdf.save(`sail-${fdate}.pdf`);
        });
    };

    useEffect(() => {
        if(sailNo) {
            ApiListBoarding(sailNo);
        }
    }, [sailNo]);

    const ApiListBoarding = (sailing_no) => {
        const url = "boarding/boarding_pass_user_list";
        let body = { sailing_no: sailing_no}
        Api(body, url, _token).then(
            (response) => {
                const result = response.result || response;
                if (result.code !== 0 ) {
                    alert(result.message);
                    return;
                }
                const dataCount = response.data.info.count;
                const dataList = response.data.user_list;
                setUserList(dataList);
                setSailInfo(response.data.info);

            }
        );
    };


    return (
        <>
            <div ref={ref} id="print-area-pass">
                <div className="print-page">
                    <div className="text-9">
                        ■ 마리나항만의 조성 및 관리 등에 관한 법률 시행규칙 [별지 제23호의3서식] {'<'}신설 2021. 12. 27.{'>'}
                    </div>
                    <div className="print-title mt-20 mb-10 text-center">
                        승선신고서
                    </div>

                    <div className="print-table-wrap pass">
                        <table cellSpacing="0" cellPadding="0">
                            <tbody>
                            <tr>
                                <th colSpan={4} className="text-left text-bold">
                                    1. 선박 제원 및 승선 인원 현황
                                </th>
                            </tr>
                            <tr>
                                <th className="pw-20">선 명</th>
                                <td className="pw-30">{sailInfo.cruise_name}</td>
                                <th className="pw-20">톤 수</th>
                                <td className="text-right">{sailInfo.yacht_weight}톤</td>
                            </tr>
                            <tr>
                                <th className="pw-20">승선 인원</th>
                                <td>승객: {sailInfo.boarding_user_count}</td>
                                <td colSpan={2} className="left-no">선원: {sailInfo.mate_count}</td>
                            </tr>
                            </tbody>
                        </table>

                        <table cellSpacing="0" cellPadding="0" className="top-no">
                            <tbody>
                            <tr>
                                <th colSpan={4} className="text-left text-bold">
                                    2. 승선자 및 출항ᆞ입항 사항
                                </th>
                            </tr>
                            <tr>
                                <td className="pw-25">출항일시: {sailInfo.start_at}</td>
                                <td className="pw-25 left-no">출항지: {sailInfo.location_address}</td>
                                <td className="pw-25">입항일시: {sailInfo.end_at}</td>
                                <td className="pw-25 left-no">입항지: {sailInfo.location_address}</td>
                            </tr>
                            </tbody>
                        </table>
                        <table cellSpacing="0" cellPadding="0" className="top-no">
                            <tbody>
                            <tr>
                                <th className="w-50">연번</th>
                                <th className="pw-25">이름</th>
                                <th className="pw-25">생년월일</th>
                                <th className="pw-25">연락처</th>
                                <th className="pw-25">비고</th>
                            </tr>

                            {userList.map(({boarding_no,boarding_name,boarding_birth,boarding_phone,boarding_gender,boarding_at,port_entry_at},k) =>
                                <tr key={k}>
                                    <td>{k+1}</td>
                                    <td>{boarding_name}</td>
                                    <td>{boarding_birth}</td>
                                    <td>{G.Hypen(boarding_phone)}</td>
                                    <td> </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>

            <div className="float-wrap">
                <ReactToPrint
                    trigger={() => <button type="button" className="btn btn-primary btn-wide">
                        <PrinterIcon className="w-6 h-6" />  Print
                    </button>}
                    content={() => ref.current}
                />
                <div className="divider divider-vertical my-1"></div>
                <button type="button" className="btn btn-info btn-wide"
                        onClick={printPDF}>
                    <ArrowDownTrayIcon className="w-6 h-6" /> PDF
                </button>
            </div>
        </>
    )
}
