import axios from "utils/axios";
import chroma from "chroma-js";
import React from "react";
import dayjs from "dayjs";

const _token = localStorage.getItem('accessToken');
export const uuidUrl = 'https://cruise.yachtplay.kr/boarding/view?v=';
export const qrUrl = 'https://cruise.yachtplay.kr/boarding/qr?v=';
const cssNames = [
    'bg-amber-100 text-amber-800',
    'bg-lime-100 text-lime-800',
    'bg-teal-100 text-teal-800',
    'bg-indigo-100 text-indigo-800',
    'bg-fuchsia-100 text-fuchsia-800',
    'bg-rose-100 text-rose-800',
    'bg-yellow-100 text-yellow-800',
    'bg-emerald-100 text-emerald-800',
    'bg-violet-100 text-violet-800',
    'bg-pink-100 text-pink-800',
    'bg-orange-100 text-orange-800',
];
export const colorYacht = [
    '#00B8D9','#FFC400','#36B37E',
    '#FF5630','#00875A','#FF8B00',
    '#5243AA','#253858','#0052CC',
    '#666666'];
export const colorCruise = [
    '#00B8D9','#FFC400','#36B37E',
    '#FF5630','#00875A','#FF8B00',
    '#5243AA','#253858','#0052CC',
    '#666666'];
export function errorStyle(err) {
    if(err) return "input-error";
    else return null;
}
export function errorDesc(err) {
    if(err) return <small className="text-xs italic text-red-500">{err.message}</small>
    else return null;
}
export function getStyleYacht(no, name, yachtList) {
    const index = yachtList.findIndex(yacht => yacht.yacht_no === no) ;
    const color = chroma(colorYacht[index] || '#0052CC');
    // return <span className='yacht-name' style={{backgroundColor: color.alpha(0.2).css(), color: colorYacht[index]}}>{name}</span>;
    return <span className='yacht-name' style={{backgroundColor: color.alpha(0.2).css()}}>{name}</span>;
}
// export function getStyleYacht(no, yachtList) {
//     const index = yachtList.findIndex(yacht => yacht.yacht_no === no);
//     return 'yacht-name '+ cssNames[index];
// }
export function getStyleSailStatus(code, txt) {
    switch (code) {
        case "SL01":
            if(txt === '출항전')
                return <span className="text-gray-700">{txt}</span>
            else
                return <span className="text-gray-400">{txt}</span>
        case "SL02":
            return <span className="text-blue-600 font-bold">{txt}</span>
        case "SL03":
            return <span className="text-green-600 font-bold">{txt}</span>
        default:
            return <span className="text-gray-700">{txt}</span>
    }
};
export function getSailStatusStyleText(sailInfo) {
    const txt = getSailStatusText(sailInfo);
    const status_cd = sailInfo.status_cd;
    return getStyleSailStatus(status_cd, txt);
}
export function getSailStatusText(sailInfo) {
    let txt = sailInfo.status_name;
    const reserve_date = sailInfo.start_at;
    const today = dayjs();
    const date_reserve = dayjs(reserve_date);
    const date_diff = (today.diff(date_reserve, "second")); // 마이너스 안지남
    // 항해중/항해완료/미출항
    // console.log('date_diff',date_diff);
    if(sailInfo.sailing_start_at) {
        if (sailInfo.sailing_finish_at) // 항해종료
            txt = '항해완료';
        else
            txt = '항해중';
    } else { // 항해시작 없음
        if(date_diff <= 60*60*2) // - 안지난초 + 지난초
            txt = '출항전';
        else // 항해예정일 오늘보다 큼
            txt = '미출항';
    }
    return txt;
};
export function Hypen(code) {
    return code
      .replace(/[^0-9]/g, '') // 숫자를 제외한 모든 문자 제거
      .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
}
export function GenderText(code) {
    switch (code) {
        case "M":
        case "남":
        case "남자":
            return <span className="text-blue-600">남</span>
        case "F":
        case "여":
        case "여자":
            return <span className="text-pink-600">여</span>
        default:
            return <span className="text-green-600">{code}</span>
    }
}
export const ApiSmsCodeInfo = async (code) => {
    let body = {
        p_code : code
    }
    const url = "code/sms_list";
    axios.defaults.headers.common['Authorization'] = _token;
    const response = await axios.post(url, body)
      .then((res) => res.data)
      .catch((err) => {
          console.log('ERROR', err);
          return err;
      });
    // console.log('response',response);
    return  response.data;
};

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms)); // await sleep(5000)

export function ReplaceTemplate(message, sail=null, reserve=null) {
    let tmp_msg = '';
    if(sail) {
        tmp_msg = message
          .replace('##YACHT_NAME##',sail.cruise_name)
          .replace('##RESERVE_DATE##',sail.start_at_format)
          .replace('##LOCATION_NAME##',sail.location_name)
          .replace('##LOCATION_LINK##',sail.location_link)
    }
    if(reserve) {
        if(reserve.uuid)
            tmp_msg = tmp_msg.replace('##URL_WRITE##',uuidUrl + reserve.uuid)
    }
    return tmp_msg;
}

// 승선캘린더-회차
export const SAIL_GROUP = 'sms_sail_group'; // -단체메시지보내기
// 항해회차 및 승선위치 안내// [예약자대상] 승선신고서 미작성 안내// 직접작성
export const SAIL_UPDATE = 'sms_sail_update'; // -[S]항해 회차 정보 수정
// 등록된 모든 승선자들에게 문자보내기// 예약자에게만 문자보내기// 보내지 않기
export const RESERVE_CREATE = 'sms_reserve_create'; // -[S]예약등록
// 안내 문자보내기// 보내지 않기// 예약
export const RESERVE_UPDATE = 'sms_reserve_update'; // -[S]예약정보수정
// 예약 인원 변경// 직접 작성// 보내지 않기

export const RESERVE_INDIVIDUAL = 'sms_reserve_individual'; // -[개]예약자 개별 메시지
// 승선 QR 발송하기// 항해회차 및 승선위치 발송하기// 직접작성
export const BOARDING_INDIVISUAL = 'sms_boarding_individual'; // -[개]탑승자개별메시지
// export const RESERVE_KEYMAN = 'sms_reserve_keyman'; // -예약자 대상 메세지보내기
export const RESERVE_BOARDING = 'sms_reserve_boarding'; // -예약별 승선자
// SAIL_GROUP
// -회차 단체메시지


export const PASS_GROUP= 'sms_pass_group'; // 승선신고 // -[S]승선자대상 메시지 보내기

export const SailInfo = {
    boarding_writer_count: '',
    created_at: '',
    mate_name: '',
    mate_no: '',
    reserve_count: '',
    reserve_total_user_count: '',
    sailing_id: '',
    sailing_no: '',
    start_at: '',
    end_at: '',
    start_at_format: '',
    status_cd: '',
    status_name: '',
    cruise_name: '',
    yacht_no: '',
    embark_max_count: '',
    max_crew_members: '',
    location_address: '',
    location_name: '',
    location_link: '',
}

export const ReserveInfo = {
    reserve_no: '',
    reserve_id: '',
    sailing_no: '',
    yacht_no: '',
    cruise_name: '',
    mate_no: '',
    mate_name: '',
    created_at: '',
    uuid: '',
    reserve_name: '',
    reserve_phone: '',
    reserve_user_count: '',
    reserve_status_cd: '',
    reserve_status_name: '',
    boarding_writer_count: '',
    boarding_user_count: '',
    emergency_contact: '',
    memo: '',
}
