import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import dayjs from "dayjs";

import Api from "utils/Api";
import * as Auth from "components/Auth";
import * as G from "utils/utils";
import InputDateTimePicker from "components/InputDateTime";
import {toast} from "store/toastSlice";
import {clearSail} from "store/sailSlice";
import {ArrowLongLeftIcon} from "@heroicons/react/24/outline";
import InputEndDateTimePicker from "components/InputEndDateTime";
import SelectGender from "components/SelectGender";
import DatePicker from "react-datepicker";
import InputMDateTimePicker from "components/InputMDateTime";

const defaultValues = {
    cruise_no: '',
    mate_no: '',
    sub_mate_no1: '',
    sub_mate_no2: '',
    start_at: null,
    end_at: null,
    memo: '',
    send_type: '',
    sms_message: '',
    duration_time: null
}
const intiMateNo = {mate_no: '',sub_mate_no1: '', sub_mate_no2: ''}
let crewArr = {};
export default function SailForm({cruise_list, mate_list, mode, show, onFinish, onDelete}) {
    const user = Auth.AuthCheck();
    const dispatch = useDispatch();
    const sailInfo = useSelector(state => state.Sail);
    const reserveInfo = null;

    const [sailNo, setSailNo] = useState();
    const [actionText, setActionText] = useState('');
    const [mateList, setMateList] = useState([]);
    const [cruiseList, setYachtList] = useState([]);
    const [reserveCount, setReserveCount] = useState(1);
    const SMSCODE = G.SAIL_UPDATE;
    const [items, setItems] = useState([]);
    const [mateNo, setMateNo]= useState(0);
    const [seconds, setSeconds]= useState(30);


    const [start_time, setStart_time] = useState();
    const [duration_time, setDuration_time] = useState(new Date(new Date().setHours(0,30,0,0)) );

    const handleSelect = React.useCallback((next: Date) => {
      const s = dayjs(next).format('HH:mm:00');
      const tt=s.split(":");
      console.log(tt);
      setDuration_time(new Date(new Date().setHours(tt[0],tt[1],0,0)) );
      setSeconds(parseInt(tt[0])*3600 + parseInt(tt[1]*60));
      console.log(seconds);
    }, );

    const onSubmit = async (data) => {
      data.minutes  = seconds;

        if (items.length < 1) {
            alert('시간을 선택해주세요.'); return;
        }
        await G.sleep(1000);
        data.mate_no = mateNo;
        if(data.start_at) data.start_at = dayjs(data.start_at).format("YYYY-MM-DD");
        if(data.end_at) data.end_at = dayjs(data.end_at).format("YYYY-MM-DD");

        ApiModifySail(data);
        await G.sleep(1000);
    };

    const handleProductRemove = (index) => {
        const filteredPd = [...items];
        filteredPd[index].isDelete = 'Y';
        setItems(filteredPd);
    };


    const {
        register,
        setValue,
        control,
        reset,
        handleSubmit,
        formState: { isSubmitting, errors }
    } = useForm({defaultValues});


    const handleProductAdd = () => {
         console.log(dayjs(start_time).format("HH:mm"));

        if (start_time == undefined) {
            alert('시작일을 선택해주세요.'); return;
        }
        console.log(items);
        let s  = false;
        items.filter(user => {
            if (user.start_at.toString().trim() === dayjs(start_time).format("HH:mm").toString().trim() &&  user.isDelete !=='Y') {
                s = true;
            }
        });
        if (s) {
            alert('이미 시작한 시간입니다. 다시 선택해주세요.'); return;
        }


        setItems([
            ...items,
            { start_at: dayjs(start_time).format("HH:mm") },
        ]);
        setStart_time(undefined);
    };


    const handleDelete = () => {
        onDelete(sailInfo);
    }

    const [smsCodeList, setSmsCodeList] = useState([]);
    const setSmsMessage = (no) => {
        // const message = G.ReplaceTemplate(smsCodeList[no].code_desc, sailInfo, reserveInfo);
        // setValue('sms_message', message);
        setValue('sms_message', smsCodeList[no].code_desc);
    }


    const handleChange = (
      index: number,
      e: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const list = [...items];
        list[index].start_at = dayjs(e).format("HH:mm") ;
        setItems(list);
    };

    useEffect(() => {
        if(show === true) {
            setSailNo(sailInfo.sailing_no);
            G.ApiSmsCodeInfo(SMSCODE)
              .then((codes) => {
                  setSmsCodeList(codes);
              });
            if(mode === 'create') {
                setActionText('생성');
                crewArr = {...intiMateNo};
                reset(defaultValues);
            } else {
                setActionText('수정');
                // setSailInfo();
                ApiReadSail(sailInfo.sailing_no)
            }
        } else {
            dispatch(clearSail(sailInfo));
            reset(defaultValues);
        }
    }, [show]);

    useEffect(() => {
        setYachtList(cruise_list);
        setMateList(mate_list);
    }, [cruise_list, mate_list]);

    const ApiReadSail = (sailing_no) => {
        let body = {
            sailing_no : sailing_no
        }
        const url = "boarding/boarding_sailing_info";
        Api(body, url, user.token).then(
          (response) => {
              const result = response.result || response;
              if (result.code !== 0 ) {
                  alert(result.message);
                  return;
              }
              console.log(response.data);;
              const sail = response.data.info;
              // console.log(sail);;
              const {company_no,mate_name,mate_no,memo,reserve_count,sailing_id,sailing_no,start_at,start_at_format,end_at,status_cd,sub_mate_name1, mates ,sub_mate_no1,sub_mate_no2,cruise_name,yacht_no} = sail;
              crewArr['mate_no'] = mate_no;
              crewArr['sub_mate_no1'] = sub_mate_no1;
              crewArr['sub_mate_no2'] = sub_mate_no2;
              setItems(mates.map((x)=> {
                  // console.log(dayjs(x.start_at).format("HH:mm").toString());
                  return {sailing_no: x.sailing_no, start_at:  x.start_at, isDelete: false};
              }));
              setMateNo(mate_no);
              setReserveCount(reserve_count);
              // const cruise = cruiseList.find(y => y.cruise_no === cruise_no);

              let objectDate = null;
              let objectEndDate = null;
              if(start_at) {
                  objectDate = new Date(start_at)
              }
              if(end_at) {
                  objectEndDate = new Date(end_at)
              }
              reset({
                  cruise_no: yacht_no,
                  mate_no: mate_no,
                  sub_mate_no1: sub_mate_no1,
                  sub_mate_no2: sub_mate_no2,
                  start_at: objectDate,
                  end_at: objectEndDate,
                  memo: memo,
                  reserve_count: reserve_count,
                  send_type: '',
                  sms_message: ''
              });
          }
        );
    };

    const ApiModifySail = (body) => {
        let url = "";
        body = {...body, times: items , mate_no: mateNo}
        if(mode === 'create') {
            url = "boarding/boarding_cruise_save";
        } else {
            body = {...body, sailing_no: sailNo,times:items, mate_no: mateNo}
            url = "boarding/boarding_cruise_update";
        }
        // console.log(body); return false;

        Api(body, url, user.token).then(
          (response) => {
              const result = response.result || response;
              if (result.code !== 0 ) {
                  alert(result.message);
                  return;
              }
              console.log("정보업데이트성공");
              dispatch(toast({message : "저장되었습니다", kind : "success"}));
              onFinish();
          }
        );
    };


    return (
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-3">
          <div className="app-bar-head">
              <div className="bar-left">
                  <button type="button" className="btn btn-ghost btn-xs px-0 mx-auto mobile-show"
                          onClick={() => onFinish()}>
                      <ArrowLongLeftIcon className="w-5 h-5 mx-1" />
                  </button>
              </div>
              <h4 className="bar-center text-lg">
                  항해 회차 {actionText}
              </h4>
              <div className="bar-right"></div>
          </div>

          <div className="divider my-1"></div>

          <div className="form-row">
              <div className="w-full">
                  <div className="form-control w-full">
                      <label className="label">
                          <span className="label-text">크루즈선택</span>
                      </label>
                      <select
                        name="cruise_no"
                        {...register("cruise_no", {
                            required: "크루즈선택은 필수 입력입니다.",
                        })}
                        // onChange={handleYachtMaxCrew}
                        className="select select-bordered w-full">
                          <option value="">크루즈선택</option>
                          {cruiseList.map(({cruise_no, cruise_name}) =>
                            <option key={cruise_no} value={cruise_no}>
                                {cruise_name}
                            </option>
                          )}
                      </select>
                      {G.errorDesc(errors.cruise_no)}
                  </div>
              </div>
          </div>

          {
              mode === 'create' && <>
              <div className="form-row">
                  <div className="md:basis-full">
                      <div className="form-control w-full">
                          <label className="label">
                              <span className="label-text">회차 시작 일시</span>
                          </label>
                          <InputDateTimePicker
                            control={control}
                            register={register}
                          />
                          {G.errorDesc(errors.start_at)}
                      </div>
                  </div>
              </div>

              <div className="form-row">
                  <div className="md:basis-full">
                      <div className="form-control w-full">
                          <label className="label">
                              <span className="label-text">회차 종료 일시</span>
                          </label>
                          <InputEndDateTimePicker
                            control={control}
                            register={register}
                          />
                          {G.errorDesc(errors.end_at)}
                      </div>
                  </div>
              </div>

          {items.filter(item => item.isDelete !=='Y').map((item, index)  => (
              <div className="md:join mt-2" key={index}>
              <div className="mobile-row md:basis-1/2 mr-2">
              <DatePicker
              // dateFormat="yyyy-MM-dd"
              timeFormat="HH:mm"
              dateFormat="HH:mm"
              // dateFormatCalendar="yyyy년 MM월"
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={60}
              timeCaption="시작시간"
              placeholderText="시작시간"
              selected={new Date('2024-05-20' + " " + item.start_at)}
              onChange={e => handleChange(index, e)}
              className="start-date input input-bordered w-full placeholder-gray-500 "
              />
              </div>
              <div className="mobile-row md:basis-1/2">
                  <div className="form-control w-full">
                      <button type="button" className="btn btn-outline w-full md:max-w-xs" onClick={() => { handleProductRemove(index) }}>삭제</button>
                  </div>
              </div>
              </div>
              ))}
                  <div className="md:join mt-2"  style={{'display':'flex'}}>
                      <div className="mobile-row md:basis-1/2 mr-2" style={{'width':'70%'}}>
                          <DatePicker
                            name="start_time"
                            // dateFormat="yyyy-MM-dd"
                            timeFormat="HH:mm"
                            dateFormat="HH:mm"
                            // dateFormatCalendar="yyyy년 MM월"
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={60}
                            timeCaption="시작시간"
                            placeholderText="시작시간"
                            selected={start_time}
                            onChange={(data) => setStart_time(data)}
                            className="start-date input input-bordered w-full placeholder-gray-500 "
                          />
                      </div>

                      <div className="mobile-row md:basis-1/2" style={{'width':'30%'}} >
                          <div className="form-control w-full">
                              <button type="button" className="btn btn-outline w-full md:max-w-xs"
                                      onClick={handleProductAdd}
                              >
                                  추가
                              </button>
                          </div>
                      </div>
                  </div>
              </>
          }
          <div className="md:join" style={{'width':'100%'}}>
              <div className="mobile-row  mr-2"  style={{'width':'100%'}}>
                  <DatePicker
                    name="duration_time"
                    // dateFormat="yyyy-MM-dd"
                    timeFormat="HH:mm"
                    dateFormat="HH:mm"
                    // dateFormatCalendar="yyyy년 MM월"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    timeCaption="운행시간"
                    timeConstraints={{
                        hours: { min: 0, max: 5 },
                    }}
                    minTime={new Date().setHours(0, 30)}
                    maxTime={new Date().setHours(6, 59)}

                    placeholderText="운행시간"
                    selected={duration_time}
                    onChange={handleSelect}
                    className="start-date input input-bordered w-full placeholder-gray-500 "
                  />
              </div>
          </div>
          { mode !== 'create' &&
             <div className="form-row">
              <div className="md:basis-full">
                  <div className="form-control w-full">
                      <label className="label">
                          <span className="label-text">항해 시작 일시</span>
                      </label>
                      <InputMDateTimePicker
                        control={control}
                        register={register}
                      />
                      {G.errorDesc(errors.start_at)}
                  </div>
              </div>
            </div>
          }

          {mode !== 'create' ?
            <div className="form-row">
                <div className="md:basis-full">
                    <div className="form-control w-full">
                        <label className="label">
                            <span className="label-text">항해 회차</span>
                        </label>
                        <input type="text"
                               placeholder="항해 회차"
                               defaultValue={sailInfo.sailing_id}
                               readOnly={true}
                               className="input input-bordered w-full"
                        />
                    </div>
                </div>
            </div>
            : null }
          <div className="form-row">
              <div className="md:basis-full">
                  <div className="form-control w-full">
                      <label className="label">
                          <span className="label-text">메모</span>
                      </label>
                      <textarea type="text"
                                placeholder="메모"
                                name="memo"
                                {...register("memo")}
                                className={`textarea textarea-bordered w-full`}
                      ></textarea>
                  </div>
              </div>
          </div>
          {mode !== 'create' ?
            <>
                <div className="form-row">
                    <div className="md:basis-full">
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">문자발송</span>
                            </label>

                            {smsCodeList.map(({code, code_name},k) =>
                              <label key={k} className="label cursor-pointer justify-start">
                                  <input type="radio"
                                         name="send_type"
                                         {...register('send_type', {
                                             required: "문자발송 여부는 필수 입력입니다.",
                                         })}
                                         value={code}
                                         onClick={()=>setSmsMessage(k)}
                                         className="radio " />
                                  <span className="label-text mx-2">{code_name}</span>
                              </label>
                            )}
                            {G.errorDesc(errors.send_type)}

                            <p className="mt-2 mb-1 text-purple-700 text-sm italic text-point ">
                                회차 정보가 변경될 경우 문자 발송을 추천드립니다.
                            </p>
                            <p className="mb-2 text-purple-700 text-sm italic text-point">
                                해당 회차에 예약 정보가 있으면 삭제할 수 없습니다. 모든 예약을 삭제 후에 삭제 진행해 주세요.
                            </p>

                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="md:basis-full">
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">발송 메세지</span>
                            </label>
                            <textarea type="text"
                                      placeholder="발송 메세지"
                                      name="sms_message"
                                      {...register("sms_message")}
                                      className={`textarea textarea-bordered w-full`}
                            ></textarea>
                            {G.errorDesc(errors.sms_message)}
                        </div>
                    </div>
                </div>
            </> : null}

          {show &&
          <div className="mb-6 flex justify-center gap-4 text-center">
              <button type="submit" disabled={isSubmitting}
                      className="btn btn-primary btn-wide sm:max-w-sm">
                  {actionText}
              </button>
              {mode !== 'create' && reserveCount < 1  ?
                <button type="button" className="btn btn-outline"
                        onClick={handleDelete}>
                    삭제
                </button>
                :
                null
              }
          </div>
          }

      </form>
    )
}
