import React, {useEffect, useState} from "react"
import {useNavigate, useLocation} from "react-router-dom";

import image1 from "styles/images/bg1.jpg";
import image2 from "styles/images/bg2.jpg";
import image3 from "styles/images/bg3.jpg";
import * as Auth from "components/Auth";

export default function Home() {
    const user = Auth.AuthCheck();
    const navigate = useNavigate();
    const [randomImg, setRandomImg] = useState();
    const [code, setCode] = useState();
    const imgArray = [image1, image2, image3];
    function showImage() {
        const imgNum = Math.round(Math.random() * 2);
        setRandomImg(imgArray[imgNum]);
        //setTimeout(showImage, 5000);
    }
    const handleCode = (e) => {
        setCode(e.target.value);
    }
    const navigateWriteCode = () => {
        window.location.replace(`/boarding/view?v=${code}`);
    }
    useEffect(() => {
        showImage();
        // const timeId = setInterval(() => showImage(), 3000)
        // return () => {
        //     clearInterval(timeId)
        //     console.log('clearInterval')
        // }
    }, []);
    return (
        <>
            <div className="hero flex-1" style={{backgroundImage: `url(${randomImg})`}}>
                {/*<div className="hero-overlay bg-opacity-20"></div>*/}
                <div className="flex flex-col sm:flex-row text-center items-center text-neutral-content m-w-full">
                    <div className="w-84 p-5">
                        <h1 className="mb-5 text-5xl font-bold">승선관리</h1>
                        <p className="mb-5 leading-8">승선관리</p>
                        <a href="/manage/" className="btn btn-primary">승선관리접속</a>
                    </div>
                    {/*<div className="w-84 p-5">*/}
                    {/*    <h1 className="mb-5 text-5xl font-bold">운항관리</h1>*/}
                    {/*    <p className="mb-5 leading-8">운항관리</p>*/}
                    {/*    <a href="/sailing/" className="btn btn-primary">운항관리접속</a>*/}
                    {/*</div>*/}
                    <div className="w-84 p-5">
                        <h1 className="mb-5 text-5xl font-bold">요트QR</h1>
                        <p className="mb-5 leading-8">요트QR</p>
                        <a href="/reader/" className="btn btn-primary">요트QR접속</a>
                    </div>
                    {/*<div className="w-84 p-5">*/}
                    {/*    <h1 className="mb-5 text-5xl font-bold">승선명부</h1>*/}
                    {/*    <p className="mb-5">*/}
                    {/*        <input type="text" onChange={handleCode} placeholder="!Q코드 끝자리 입력 xxxxxxx" className="input input-sm text-black input-bordered w-full" />*/}
                    {/*    </p>*/}
                    {/*    <button type="button" onClick={navigateWriteCode} className="btn btn-primary">승선명부작성</button>*/}
                    {/*</div>*/}
                </div>
            </div>
        </>
    );
}

